import React from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useTranslation } from 'react-i18next';
import { CarWindowEquipment } from './car-compare-equipment';
import { AppSearchParamKeys } from '@japieglobal/shared/src/components';

const getColorByCar = (value: number) => {
  if (value > 0 && value < 30) return '#EFC241'; //yellow
  if (value >= 30 && value < 60) return '#E27D3B'; //orange
  return '#E34E47'; //red
};
interface CarWindowEquipmentCellProps {
  car: CarResult;
  order?: string[];
  isOfficialMake: boolean;
}

export const CarWindowEquipmentCell = React.memo(({ car, isOfficialMake }: CarWindowEquipmentCellProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
      {isOfficialMake && (
        <CarWindowEquipment
          searchParamKey={AppSearchParamKeys.CLICK_EQUIPMENT_OFFICIAL_MAKE}
          car={car}
          equipmentOption={'official_make'}
        />
      )}
      <CarWindowEquipment
        searchParamKey={AppSearchParamKeys.CLICK_EQUIPMENT_IS_DEALER}
        car={car}
        equipmentOption={'is_dealer'}
      />
      <CarWindowEquipment
        searchParamKey={AppSearchParamKeys.CLICK_EQUIPMENT_IN_STOCK}
        car={car}
        equipmentOption={'in_stock'}
        onHoverContent={`${car.sold?.since} ${t('DAYS_AGO')}`}
        hideBadge={false}
        badgeColor={car.sold?.since && car.sold?.since > 0 ? getColorByCar(car.sold.since) : undefined}
      />
    </div>
  );
});
