export enum BooleanAppSearchParam {
  OFFICIAL_DEALER = 'official_dealer',
  SHOW_EXTRA_FILTERS = 'show_extra_filters',
  SHOW_TREND_LINE = 'show_trend_line',
  SHOW_MARKET_DATA = 'show_market_data',
  SHOW_HIDDEN_CARS = 'hidden',
  SHOW_PRICE_PROPOSAL_CARS = 'show_price_proposals',
  SHOW_MESSAGES_CARS = 'show_car_messages',
  SHOW_TRASH_BIN_CARS = 'show_trash_bin',
  SHOW_REFERENCE_LINE = 'show_reference_line',
  MISSING_OPTIONS = 'missing_options',
  EQUIPMENT_FOUR_DOORS = 'equipment_four_doors',
  THREE_THUMBS_UP = 'three_thumbs_up',
  THREE_THUMBS_DOWN = 'three_thumbs_down',
  THREE_THUMBS_DOWN_30 = 'three_thumbs_down_30',
  THREE_THUMBS_DOWN_60 = 'three_thumbs_down_60',
  THREE_THUMBS_DOWN_90 = 'three_thumbs_down_90',
  WHEELBASE = 'wheelbase',
  SORT_APR = 'sort_apr',
  SORT_CLICKS = 'sort_clicks',
  SORT_LEADS = 'sort_leads',
  SORT_STOCK_DAYS = 'sort_stock_days',
  SORT_TOTAL_ROW = 'sort_total_row',
  SORT_TURNOVER_EXT_TREND = 'turnover_ext_trend',
  SORT_TURNOVER_INT_TREND = 'turnover_int_trend',
  SORT_STOCK_DAYS_CLIENT = 'sort_stock_days_client',
  SORT_TURNOVER_EXT = 'turnover_ext',
  SORT_TURNOVER_INT = 'turnover_int',
  OWN_STOCK = 'own_stock',
  COMMERCIAL_VEHICLES = 'commercial_vehicles',
  IS_COMMERCIAL = 'is_commercial',
  THUMBS_DOWN = 'thumbs_down',
  USE_PAID_OPTIONS = 'use_paid_options',
  SHOW_PRICE_EXEX = 'show_price_exex',
  SHOW_YEAR_OVERVIEW = 'show_year_overview',

  CLICK_OWN_SUPPLY = 'click_own_supply',
  CLICK_EQUIPMENT_IS_DEALER = 'click_equipment_is_dealer',
  CLICK_EQUIPMENT_OFFICIAL_MAKE = 'click_equipment_official_make',
  CLICK_BUILD = 'click_build',
  CLICK_MODEL_YEAR = 'click_model_year',
  CLICK_HP = 'click_hp',
  CLICK_STAT_LOCAL_CAR = 'click_stat_local_car',
  INCLUDE_BANNED = 'include_banned',
  CLICK_POLYGON = 'click_polygon',
  CLICK_LOCATION = 'click_location',
}
