import { SuperAdminResponseType } from './activity';
import { instance } from './instance';

export enum UserPermission {
  TAXATION = 'taxation',
  SHOW_PURCHASE_PRICE = 'showPurchasePrice',
  SHOW_TOPDOWN_VALUE = 'showTopDownValue',
  SHOW_MATURITY_VALUE = 'showMaturityValue',
  PRICE_PROPOSAL = 'priceProposal',
  FILE_VALUATION = 'fileValuation',
  USER_ACTIVITY = 'userActivity',
  API = 'api',
  PROTOCOL = 'protocol',
  PURCHASE = 'purchase',
  APR = 'apr',
  TAXATION_SCOPE = 'taxationScope',
  MONITORING = 'monitoring',
  RECOMMENDATION = 'recommendation auctions',
  SYSTEM_ADMIN = 'systemAdmin',
  TAXATION_LOCATION = 'taxationLocation',
  AUCTION_IMPORT = 'auctionImport',
  MONITORING_TAXATION_BUTTON = 'monitoringTaxationButton',
  ITR = 'itr',
  EMAIL = 'email',
  LOCATIONS = 'locations',
  SUPPLY_TYPE = 'supply_type',
  CAR_WINDOW_MAP = 'carWindowMap',
  B2B = 'b2b',
  UPLOAD_PURCHASE = 'upload_purchase',
  UPLOAD_DEMO = 'upload_demo',
  UPLOAD_NEW = 'upload_new',
  UPLOAD_STOCK_DAYS_CLIENT = 'upload_stock_days_client',
  SCRAPE_MANAGEMENT = 'scrapeManagement',
  SCRAPE_USER = 'scrapeUser',
  INTERNATIONAL_TRADER_BE = 'internationalTrader_be',
  INTERNATIONAL_TRADER_DE = 'internationalTrader_de',
  INTERNATIONAL_TRADER_SE = 'internationalTrader_se',
  INTERNATIONAL_TRADER_DK = 'internationalTrader_dk',
}

export enum UserRole {
  SUPER_ADMIN = 'super-admin',
  ADMIN = 'admin',
  USER = 'user',
  SCRAPE_USER = 'scrape-user',
}

export interface User {
  full_name?: string;
  first_name?: string;
  last_name?: string;
  permissions: UserPermission[];
  notifications: string[];
  locations: string[];
  allowed_dealers: string[];
  role: UserRole;
  id: string;
  updated_at: string;
  created_at: string;
  created_by: string;
  email: string;
  phone_number: string;
  password: string;
  country: string;
  language: string;
  dealer: string;
  is_verified?: boolean;
  password_reset_token_expires_at?: string;
  password_reset_token?: string;
  allowed_locations: Record<string, string[]>;
  last_activity?: string;
  super_admin_password?: string;
  is_key: boolean;
  two_factor_authentication?: boolean;
  receive_price_proposal_email?: boolean;
  receive_management_report_email?: boolean;
  is_service_account: boolean;
  token?: string;
  is_super_admin_password_set: boolean;
  quotas: Record<string, number | undefined>;
  minimal_apr: number;
}

export const getUser = async (): Promise<User> => instance.get(`/users/me`).then((res) => res.data);

export const setLanguage = async (language: string) => instance.patch('/users/me/language', { language: language });

export const updateUser = async ({ id, body }: { id: string; body: User }) => instance.patch(`/user/${id}`, body);

export const getUserById = async ({ userId }: { userId: string }): Promise<User> =>
  instance.get(`/user/${userId}`).then((res) => res.data);

export const listScrapeAllocatedDealers = async (params: { role?: string }): Promise<string[]> =>
  instance.get('/users/allocated_dealers', { params }).then((res) => res.data);

export const changePhoneNumber = async (phoneNumber: string) =>
  instance.patch('/users/me/phone-number', { phone_number: phoneNumber });

export const addPhoneNumber = async (phoneNumber: string) =>
  instance.patch('/users/me/add-phone-number', { phone_number: phoneNumber });

export const setDealer = async (dealer: string) =>
  instance.post('/user/dealer/change', { dealer }).then((res) => res.data);

export const deleteUsers = async (body: { user_ids: string[] }) => instance.delete(`/users`, { data: body });

export const getDealerUsers = async () => instance.get(`/dealer/users`).then((res) => res.data);

export const userSendVerificationMail = async ({ userId }: { userId: string }) =>
  instance.post(`/user/${encodeURIComponent(userId)}/verification_email`).then((res) => res.data);

interface SetSuperAdminPasswordProps {
  userId: string;
  super_admin_password: string;
}

export const setSuperAdminPassword = async ({ userId, super_admin_password }: SetSuperAdminPasswordProps) =>
  instance
    .post(`/user/${encodeURIComponent(userId)}/superAdminPassword`, { super_admin_password })
    .then((res) => res.data);

export const deleteSuperAdminPassword = async ({ userId }: { userId: string }) =>
  instance.delete(`/user/${encodeURIComponent(userId)}/superAdminPassword`).then((res) => res.data);

export const userCreate = async ({ user }: { user: User }) => instance.post(`/users/`, user).then((res) => res.data);

export const superAdminList = async (): Promise<SuperAdminResponseType[]> =>
  instance.get(`/superadmin/list`).then((res) => res.data);

export const adminList = async (): Promise<SuperAdminResponseType[]> =>
  instance.get(`/admin/list`).then((res) => res.data);

export interface Notification {
  name: string;
  description: string;
  required_role?: UserRole;
}

export const notificationList = async (role?: UserRole): Promise<Notification[]> =>
  instance.get(`/notifications`, { params: { role: role } }).then((res) => res.data);

export enum PermissionCategory {
  MONITORING = 'MONITORING',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SCRAPE_ADMIN = 'SCRAPE_ADMIN',
  TAXATION = 'TAXATION',
  OTHER = 'OTHER',
  API = 'API',
  PROTOCOL = 'PROTOCOL',
  UPLOAD = 'UPLOAD',
}

export interface Permission {
  name: UserPermission;
  category: PermissionCategory;
  description: string;
}

export const getPermissions = async (): Promise<Permission[]> => instance.get('/permissions').then((res) => res.data);
