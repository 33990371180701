import { Link, Menu, MenuItem, styled } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { CarResult } from '@japieglobal/shared/src/types';
import React from 'react';
import { ColoredButtonSmall } from '../../monitoring/results/result-table-styles';

const PortalsButton = styled(ColoredButtonSmall)({
  height: '30px',
  width: '40px',
});

export const ShoppingCartButton = ({ car }: { car: CarResult }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    (car.url_gaspedaal || car.url_autoscout24 || car.url_mobile) && (
      <>
        <PortalsButton aria-haspopup="true" onClick={handleClick}>
          <ShoppingCartOutlinedIcon fontSize="small" />
        </PortalsButton>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {[
            ['Gaspedaal.nl', car.url_gaspedaal],
            ['AutoScout24', car.url_autoscout24],
            ['Mobile.de', car.url_mobile],
          ].map(
            (portal) =>
              portal[1] && (
                <MenuItem
                  key={portal[0]}
                  onClick={handleClose}
                  sx={(theme) => ({ color: theme.palette.primary.main, fontSize: '11px' })}
                  component={Link}
                  href={portal[1]}
                  rel="noreferrer"
                  target="_blank"
                >
                  {portal[0]}
                </MenuItem>
              ),
          )}
        </Menu>
      </>
    )
  );
};
