import { Grid2 } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppSearchParamKeys, ProgressIndicator, Tooltip } from '@japieglobal/shared/src/components';
import { TopdownHover } from './topdown-hover';
import { ResultTurnoverButton } from '../../../taxation-and-monitoring-shared/buttons/result-turnover-button/result-turnover-button';
import { ResultTableAdditionalInformation } from '../../../taxation-and-monitoring-shared/result-table-additional-information-cell/result-table-additional-information-cell';
import { ResultTaxesCell } from '../../../taxation-and-monitoring-shared/result-taxes-cell/result-taxes-cell';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { getMoney, hasPermission } from '@japieglobal/shared/src/utils';
import { CarResult } from '@japieglobal/shared/src/types';
import { PriceRow } from './price-row';
import { UserPermission } from '@japieglobal/shared/src/api';

interface ResultCountryProps {
  country: string;
  car?: CarResult;
  isLoading: boolean;
  error?: string;
}

export const ResultCountry = ({ isLoading, country, car, error }: ResultCountryProps) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const hasTopDownPermission = hasPermission(user, UserPermission.SHOW_TOPDOWN_VALUE);
  const hasAnyInternationalTraderPermission = user.permissions.some((p) => p.startsWith('internationalTrader'));

  return (
    <>
      {isLoading && <ProgressIndicator />}
      <Grid2 width={'100%'}>
        <img
          style={{ marginBottom: 10 }}
          width={30}
          height={20}
          src={`/images/flags/${country.toUpperCase()}.png`}
          title={country.toUpperCase()}
        />
        {!error && car ? (
          <>
            <PriceRow
              car={car}
              className="vvpResult"
              display={Boolean(hasAnyInternationalTraderPermission)}
              highlightedCell="target_value_exex"
              titleKey="VVP_NET"
              value={car?.rank?.target_value_exex}
            />
            <PriceRow
              car={car}
              className="tdcNetResult"
              display={Boolean(hasTopDownPermission && hasAnyInternationalTraderPermission)}
              highlightedCell="topdown_value_exex"
              titleKey="TDC_NET"
              value={car.topdown_value_exex}
              check_min_apr
            />
            <PriceRow
              car={car}
              className="vvpResult"
              highlightedCell="target_value"
              titleKey="VVP_GROSS"
              value={car.rank?.target_value}
            />
            <PriceRow
              car={car}
              className="tdcGrossResult"
              display={hasTopDownPermission}
              highlightedCell="topdown_value"
              titleKey="TDC_GROSS"
              value={car?.topdown_value}
              check_min_apr
            />
            {car?.rank?.target_value_at_maturity && (
              <>
                <Tooltip onHoverContent={t('MATURITYVALUE_TITLE')}>
                  <Grid2 container justifyContent={'space-between'}>
                    <span>{t('SHOWMATURITYVALUE')}: </span>
                    <span>{getMoney(user, car.rank?.target_value_at_maturity)}</span>
                  </Grid2>
                </Tooltip>
                {car.topdown_value_at_maturity && hasTopDownPermission && (
                  <TopdownHover
                    table_header={t('FUTURE_TOPDOWNVALUE_TITLE')}
                    value_label={t('SHOWMATURITYVALUE')}
                    topdown_value_label={t('FUTURE_TOPDOWNVALUE')}
                    topdown_value={car.topdown_value_at_maturity}
                    value={car.rank?.target_value_at_maturity}
                    topdown_value_breakdown={car.topdown_value_at_maturity_breakdown}
                  />
                )}
              </>
            )}
            {car?.rank?.target_value && (
              <Grid2 container pt={1} justifyContent={'space-between'}>
                <Grid2 container direction={'column'} alignItems={'center'}>
                  <ResultTurnoverButton
                    takeSpaceWhenEmpty={false}
                    car={car}
                    searchParamKey={AppSearchParamKeys.SORT_TURNOVER_INT}
                    column="itr"
                  />
                  <ResultTurnoverButton
                    takeSpaceWhenEmpty={false}
                    car={car}
                    searchParamKey={AppSearchParamKeys.SORT_TURNOVER_EXT}
                    column="etr"
                  />
                  {car?.apr != null && (
                    <ResultTurnoverButton
                      takeSpaceWhenEmpty={false}
                      car={car}
                      searchParamKey={AppSearchParamKeys.SORT_APR}
                      column="apr"
                    />
                  )}
                </Grid2>
                <Grid2>
                  <ResultTaxesCell car={car} />
                  <ResultTableAdditionalInformation car={car} />
                </Grid2>
              </Grid2>
            )}
          </>
        ) : (
          <Grid2 sx={{ color: '#656565' }}>{t('NOT_AVAILABLE')}</Grid2>
        )}
      </Grid2>
    </>
  );
};
