import { Grid2 } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@japieglobal/shared/src/components';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { getMoney } from '@japieglobal/shared/src/utils';
import { CarResult } from '@japieglobal/shared/src/types';
import { CarPriceOverview, HighlightCell } from './price-hover';

interface PriceRowProps {
  car: CarResult;
  value?: number;
  className: string;
  titleKey: string;
  highlightedCell: HighlightCell;
  display?: boolean;
  check_min_apr?: boolean;
}

export const PriceRow = ({
  car,
  value,
  className,
  titleKey,
  highlightedCell,
  display = true,
  check_min_apr = false,
}: PriceRowProps) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const hideTopdownPrices = car.apr !== undefined && user.minimal_apr > car.apr;
  if (check_min_apr && hideTopdownPrices) return <></>;
  return value && display ? (
    <Tooltip
      placement="right"
      onHoverContent={
        <CarPriceOverview highlightedCell={highlightedCell} car={car} hideTopdownPrices={hideTopdownPrices} />
      }
    >
      <Grid2 container justifyContent={'space-between'} className={className}>
        <span>{t(titleKey)}: </span>
        <span>{getMoney(user, value)}</span>
      </Grid2>
    </Tooltip>
  ) : (
    <></>
  );
};
