import React, { useContext } from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { CarWindowOption } from './car-compare-option';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { isSuperAdmin } from '@japieglobal/shared/src/utils';

interface CarWindowOptionsCellProps {
  car: CarResult;
  ownCar: CarResult;
  order?: string[];
}
export const CarWindowOptionsCell = React.memo(({ car, ownCar, order }: CarWindowOptionsCellProps) => {
  const { user } = useContext(UserContext);
  if (order && isSuperAdmin(user) && car.hidden_options) {
    order = order.concat(car.hidden_options.split(','));
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', columnGap: '5px' }}>
      {order?.map((carOption) => (
        <CarWindowOption
          ownCar={ownCar}
          key={carOption}
          option={carOption}
          carHasOption={car.options?.includes(carOption)}
          isMissing={car.hidden_options?.includes(carOption)}
        />
      ))}
    </div>
  );
});
