import React, { useCallback, useEffect, useRef, useState } from 'react';
import { addCarBasedOnRank } from '../../utils/add-car-to-list-rank-based';
import { reorderList } from '../../utils/reorder-list';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks';
import { TaxationErrorView } from './taxation-error-view';
import { CarResult } from '@japieglobal/shared/src/types';
import { CarWindowResult } from '@japieglobal/shared/src/api';
import { usePriceRankingStates } from '../../states/price-ranking';
import { useAppSearchParams } from '@japieglobal/shared/src/components';
import { CarWindowPageCounter } from '../../taxation-and-monitoring-shared/car-compare-table/car-compare-page-counter';
import { CarWindowTable } from '../../taxation-and-monitoring-shared/car-compare-table/car-compare-table';

interface TaxationListViewProps {
  windowResult?: CarWindowResult;
  evaluateCar: (pageIndex?: number, pricingTarget?: number) => void;
  clickDealer?: string;
}

export const TaxationListView = React.memo(({ windowResult, clickDealer, evaluateCar }: TaxationListViewProps) => {
  const { resetPriceRanking, setPriceDiscount, setPricingTarget } = usePriceRankingStates();
  const [, setAppSearchParams] = useAppSearchParams([]);

  const [competitorCars, setCompetitorCars] = useState<CarResult[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (windowResult) {
      if (windowResult.car) {
        setCompetitorCars(addCarBasedOnRank(windowResult.car, windowResult.window, windowResult.pagable.page_size));
      }
    }
  }, [windowResult]);

  const reorder = useCallback(
    (source: number, target: number) => {
      const newTarget = competitorCars[target]?.rank?.counter;

      setCompetitorCars((prevState) => {
        return reorderList(prevState, source, target);
      });
      if (newTarget !== undefined) {
        setPriceDiscount(undefined);
        setPricingTarget(newTarget);
        evaluateCar(pageIndex, newTarget);
      }
    },
    [competitorCars, evaluateCar, pageIndex, setPricingTarget, setPriceDiscount],
  );

  useDebouncedEffect(
    () => {
      if (!isFirstRender.current) {
        evaluateCar(pageIndex);
      } else {
        isFirstRender.current = false;
      }
    },
    500,
    [clickDealer, pageIndex],
  );

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex, clickDealer]);

  const removeRanking = () => {
    resetPriceRanking();
    setAppSearchParams({ pricing_target: undefined });
    evaluateCar();
  };

  const pageCount = windowResult?.pagable?.page_count ?? 0;

  return (
    <>
      {windowResult?.car && (
        <>
          <CarWindowTable
            cars={competitorCars}
            ownCar={windowResult?.car}
            reorder={reorder}
            removeRanking={removeRanking}
            taxationView={true}
            error={windowResult?.error}
          />
          {pageCount > 0 && (
            <CarWindowPageCounter changePage={setPageIndex} pageCount={pageCount} currentPage={pageIndex} />
          )}
        </>
      )}
      {windowResult?.error && <TaxationErrorView windowResult={windowResult} />}
    </>
  );
});
