import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { DataGrid, GridColDef, GridColumnHeaderParams, GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { AdminTableHeaderField } from '../shared/styled';
import { useTimeSpan } from '../shared/period-select';
import { AdminMainWrapper } from '../admin-main-wrapper';
import {
  AdminActivityPeriod,
  AdminPageType,
  AdminQueryStateKey,
  AdminQueryStateType,
} from '../hooks/admin-query-state';
import { AdminUserChartNivo } from './admin-user-chart-nivo';
import { useQueryString } from '@japieglobal/shared/src/hooks';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks/use-debounced-effect';
import { useAdminDeleteUsers } from '../hooks/admin-delete-users';
import { renderCellExpand } from './data-grid-render-cell-expand';
import { getUserInvalidLocations } from '../utils/dealer-location-utils';
import { TFunction } from 'i18next';
import { AddIcon, DeleteIcon, DownloadIcon, MissingLocationsIcon, TableActionsIcons } from '../shared/icons';
import {
  SuperAdminResponseType,
  UserFilterItem,
  UserStaticsListResponse,
  UserStatsResponse,
} from '@japieglobal/shared/src/api';
import { getDealerLocations, getUserStatisticList, userStats, userStatsAllDownload } from '@japieglobal/shared/src/api';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-colCellTitle': {
      fontSize: '12px',
      color: 'rgba(0,0,0,.54)',
    },
    '& .MuiDataGrid-cell': {
      fontSize: '13px',
      color: 'rgba(0,0,0,.87)',
    },
  },
  smallPadding: {
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
  },
  warningWrapper: { color: '#F49F00' },
}));

const WarningWrapper = styled(`div`)({
  color: '#F49F00',
  alignContent: 'center',
  display: 'flex',
});

const StyledDataGrid = styled(DataGrid)({
  'overflow': undefined,
  'backgroundColor': 'white',
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: 'white',
  },
});

type SuperAdminResponseTypeForRows = SuperAdminResponseType & {
  id: string;
  lastActivity: Date | undefined;
  hasMissingLocations: boolean;
};

function isUserVerified(user: SuperAdminResponseType) {
  if (user.is_verified === undefined) {
    return !!user.password;
  }
  return user.is_verified;
}

const TableHeaderWrapper = styled(`div`)({
  marginBottom: '2px',
});

interface getColumnsProps {
  smallPaddingClass: string;
  isSuperAdmin: boolean;
  showActivity: boolean;
  firstNameHeader: (params: GridColumnHeaderParams) => JSX.Element;
  lastNameHeader: (params: GridColumnHeaderParams) => JSX.Element;
  emailHeader: (params: GridColumnHeaderParams) => JSX.Element;
  dealerHeader: (params: GridColumnHeaderParams) => JSX.Element;
  userFeatures: string[];
  showServiceAccounts: boolean;
  t: TFunction;
}

const getColumns = ({
  isSuperAdmin,
  showActivity,
  smallPaddingClass,
  firstNameHeader,
  emailHeader,
  dealerHeader,
  lastNameHeader,
  userFeatures,
  showServiceAccounts,
  t,
}: getColumnsProps) => {
  const result: Array<GridColDef> = [];
  if (userFeatures.includes('userActivity')) {
    result.push({ field: 'lastActivity', headerName: t('LAST_ACTIVITY'), width: 180, type: 'dateTime' });
    result.push({ field: 'totalActivities', headerName: t('TOTAL'), width: 90 });
  }
  if (isSuperAdmin && !showServiceAccounts) {
    result.push({
      field: 'is_verified',
      renderHeader: () => <></>,
      disableColumnMenu: true,
      width: 35,
      align: 'center',
      cellClassName: smallPaddingClass,
      renderCell: (params) => {
        const isVerified = params.row[params.field];
        if (!isVerified) {
          return (
            <WarningWrapper>
              <NotificationImportantIcon />
            </WarningWrapper>
          );
        }
        return <></>;
      },
    });

    result.push({
      field: 'hasMissingLocations',
      renderHeader: () => <></>,
      disableColumnMenu: true,
      width: 35,
      cellClassName: smallPaddingClass,
      renderCell: (params) => {
        const hasMissingLocations = params.row[params.field];
        if (hasMissingLocations) {
          return (
            <WarningWrapper title="Ongeldige locaties">
              <WarningIcon />
            </WarningWrapper>
          );
        }
        return <></>;
      },
    });
  }

  result.push({
    field: 'first_name',
    headerName: t('FIRST_NAME'),
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderCell: renderCellExpand,
    renderHeader: firstNameHeader,
  });
  if (!showServiceAccounts) {
    result.push({
      field: 'last_name',
      headerName: t('LAST_NAME'),
      flex: 1,
      renderCell: renderCellExpand,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: lastNameHeader,
    });
    result.push({
      field: 'email',
      headerName: 'E-mail',
      flex: 1,
      renderCell: renderCellExpand,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: emailHeader,
    });
  }
  result.push({
    field: 'dealer',
    headerName: 'Dealerholding',
    flex: 1,
    renderCell: renderCellExpand,
    disableColumnMenu: true,
    sortable: false,
    renderHeader: dealerHeader,
  });
  result.push({
    field: 'actie',
    headerName: t('ACTION'),
    width: 120,
    sortable: false,
    renderCell: (params) => <TableActionsIcons showActivity={showActivity} userId={params.id as string} />,
  });
  return result;
};

const defaultEmptyRecord = {};

interface AdminMainUserProps {
  isSuperAdmin: boolean;
  userFeatures: string[];
  adminQueryState: AdminQueryStateType;
  defaultFilters?: UserFilterItem[];
  showServiceAccounts?: boolean;
  showSuperAdmins?: boolean;
  fixedActivityType?: string[];
  title?: string;
  permissions?: string[];
}

export const AdminMainUser = React.memo(
  ({
    adminQueryState,
    isSuperAdmin,
    userFeatures,
    defaultFilters,
    showServiceAccounts = false,
    showSuperAdmins = false,
    fixedActivityType,
    title,
    permissions,
  }: AdminMainUserProps) => {
    const showActivityChart = useMemo(() => userFeatures.includes('userActivity'), [userFeatures]);

    const styles = useStyles();
    const { t } = useTranslation();
    const { page, selected, setSelectedProxy, setPageProxy } = adminQueryState;
    const { snackbarErrorHandler } = useSnackbarErrorHandler();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);
    const [isLoadingMissingLocations, setIsLoadingMissingLocations] = useState<boolean>(false);

    const firstNameRef = useRef<HTMLInputElement | null>(null);
    const lastNameRef = useRef<HTMLInputElement | null>(null);
    const emailRef = useRef<HTMLInputElement | null>(null);
    const dealerRef = useRef<HTMLInputElement | null>(null);

    const [users, setUsers] = useState<UserStaticsListResponse | undefined>(undefined);

    const [userMissingLocationMap, setUserMissingLocationMap] = useState<Record<string, boolean>>(defaultEmptyRecord);
    const [chartData, setChartData] = useState<UserStatsResponse | undefined>(undefined);

    const { selectPeriod, timespanPeriod, dateFrom, dateTo } = useTimeSpan();
    const [filters, setFilters] = useState<UserFilterItem[]>([]);

    const requestFilters = useMemo(() => (filters && filters.length > 0 ? filters : undefined), [filters]);
    const requestIds = useMemo(() => (selected && selected.length > 0 ? selected : undefined), [selected]);

    const [limit, setLimit] = useState(50);

    const [filterModelQuery, setFilterModelQuery] = useQueryString<Record<string, any> | undefined>(
      'userFilter',
      defaultEmptyRecord,
    );

    const dealerSelected = useMemo(() => filterModelQuery && 'dealer' in filterModelQuery, [filterModelQuery]);

    const showChart = useMemo(
      () =>
        (showActivityChart && dealerSelected) ||
        (showActivityChart && !dealerSelected && timespanPeriod !== 'last_60_days'),
      [dealerSelected, timespanPeriod, showActivityChart],
    );

    const [sortModelQuery, setSortModelQuery] = React.useState<GridSortModel>([
      {
        field: 'lastActivity',
        sort: 'desc',
      },
    ]);

    const [sortModel, setSortModel] = React.useState<GridSortModel>(sortModelQuery || []);
    const [activityTypeQuery] = useQueryString<string>(AdminQueryStateKey.ACTIVITY_TYPE, '');
    const activityType = useMemo(
      () => fixedActivityType || (activityTypeQuery ? [activityTypeQuery] : undefined),
      [fixedActivityType, activityTypeQuery],
    );
    const gridRef = useRef<any>(null);

    useEffect(() => {
      if (sortModel) {
        setSortModelQuery(sortModel);
      }
    }, [sortModel, setSortModelQuery]);

    const getUserActivityUrl = useCallback(
      (userId: string) => {
        let url = `/admin/${AdminPageType.ACTIVITY_LIST}?${AdminQueryStateKey.USER_ID}=${userId}&${AdminQueryStateKey.PERIOD}=${timespanPeriod}`;
        if (timespanPeriod === AdminActivityPeriod.CUSTOM_RANGE) {
          url += `&${AdminQueryStateKey.DATE_FROM}=${dateFrom}&${AdminQueryStateKey.DATE_TO}=${dateTo}`;
        }
        return url;
      },
      [timespanPeriod, dateFrom, dateTo],
    );

    useDebouncedEffect(
      () => {
        if (filterModelQuery) {
          const firstNameVal = firstNameRef.current;
          if (firstNameVal) {
            firstNameVal.value = filterModelQuery.first_name ?? '';
          }
          const lastNameRVal = lastNameRef.current;
          if (lastNameRVal) {
            lastNameRVal.value = filterModelQuery.last_name ?? '';
          }
          const emailVal = emailRef.current;
          if (emailVal) {
            emailVal.value = filterModelQuery.email ?? '';
          }
          const dealerVal = dealerRef.current;
          if (dealerVal) {
            dealerVal.value = filterModelQuery.dealer ?? '';
          }
        }
      },
      300,
      [filterModelQuery],
    );

    const offset = useMemo(() => {
      if (page) {
        return page * limit;
      }
      return 0;
    }, [limit, page]);

    const rows: SuperAdminResponseTypeForRows[] = useMemo(() => {
      if (users?.data) {
        return users.data.map((item) => {
          return {
            ...item,
            // eslint-disable-next-line no-underscore-dangle
            id: item.id,
            lastActivity: item.lastActivity ? new Date(item.lastActivity) : undefined,
            isVerified: isUserVerified(item),
            // eslint-disable-next-line no-underscore-dangle
            hasMissingLocations: userMissingLocationMap[item.id] ?? false,
          };
        });
      }
      return [];
    }, [userMissingLocationMap, users]);

    const refreshUserList = useCallback(() => {
      setIsLoading(true);
      const sort = sortModel.map((s) => [s.field === 'lastActivityDate' ? 'lastActivity' : s.field, s.sort]);
      getUserStatisticList({
        activities: activityType || undefined,
        filters: requestFilters,
        limit,
        offset,
        date_from: dateFrom,
        date_to: dateTo,
        is_service_account: showServiceAccounts,
        show_super_admins: showSuperAdmins,
        permissions,
        sort,
      })
        .then(setUsers)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }, [
      sortModel,
      requestFilters,
      limit,
      offset,
      dateFrom,
      dateTo,
      showServiceAccounts,
      showSuperAdmins,
      setUsers,
      snackbarErrorHandler,
      activityType,
      permissions,
    ]);

    useDebouncedEffect(() => refreshUserList(), 300, [requestFilters, limit, offset, refreshUserList]);

    useDebouncedEffect(
      () => {
        if (showChart && (dateFrom || dateTo)) {
          setIsLoadingChart(true);
          userStats({
            activities: activityType || undefined,
            date_from: dateFrom,
            date_to: dateTo,
            filters: requestFilters,
            ids: requestIds,
            is_service_account: showServiceAccounts,
            show_super_admins: showSuperAdmins,
            permissions,
          })
            .then(setChartData)
            .catch(snackbarErrorHandler)
            .finally(() => setIsLoadingChart(false));
        }
      },
      300,
      [
        requestFilters,
        requestIds,
        snackbarErrorHandler,
        dateFrom,
        dateTo,
        showActivityChart,
        showServiceAccounts,
        showSuperAdmins,
        permissions,
      ],
    );

    useEffect(() => {
      if (filterModelQuery) {
        const resultFilters: UserFilterItem[] = [];
        Object.entries(filterModelQuery).forEach(([key, val]) => {
          resultFilters.push({ filterOn: key, filter: val.trim() });
        });
        setFilters(resultFilters.concat(defaultFilters || []));
      }
    }, [filterModelQuery, setFilters, defaultFilters]);

    const headerHitEnter = useCallback(() => {
      const resultFilters: UserFilterItem[] = [];
      const firstNameVal = firstNameRef.current?.value;
      const lastNameVal = lastNameRef.current?.value;
      const emailVal = emailRef.current?.value;
      const dealerVal = dealerRef.current?.value;
      // const dealerVal = dealerFilter;
      if (firstNameVal && firstNameVal.trim().length > 0) {
        resultFilters.push({ filterOn: 'first_name', filter: firstNameVal.trim() });
      }
      if (lastNameVal && lastNameVal.trim().length > 0) {
        resultFilters.push({ filterOn: 'last_name', filter: lastNameVal.trim() });
      }
      if (emailVal && emailVal.trim().length > 0) {
        resultFilters.push({ filterOn: 'email', filter: emailVal.trim() });
      }
      if (dealerVal && dealerVal.trim().length > 0) {
        resultFilters.push({ filterOn: 'dealer', filter: dealerVal.trim() });
      }
      setFilterModelQuery(
        resultFilters.reduce((previousValue: Record<string, any>, currentValue) => {
          // eslint-disable-next-line no-param-reassign
          previousValue[currentValue.filterOn] = currentValue.filter;
          return previousValue;
        }, {}),
      );
      setPageProxy(0);
    }, [setFilterModelQuery, setPageProxy]);

    const firstNameHeader = useCallback(() => {
      const shrink = filterModelQuery?.first_name != null || undefined;
      return (
        <TableHeaderWrapper>
          <AdminTableHeaderField
            autoComplete="off"
            label={showServiceAccounts ? 'API User label' : t('FIRST_NAME')}
            type="search"
            InputLabelProps={{ shrink }}
            onKeyPress={(event) => (event.key === 'Enter' ? headerHitEnter() : undefined)}
            inputRef={firstNameRef}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </TableHeaderWrapper>
      );
    }, [filterModelQuery?.first_name, headerHitEnter, showServiceAccounts, t]);

    const lastNameHeader = useCallback(() => {
      const shrink = filterModelQuery?.last_name != null || undefined;
      return (
        <TableHeaderWrapper>
          <AdminTableHeaderField
            autoComplete="off"
            label={t('LAST_NAME')}
            type="search"
            InputLabelProps={{ shrink }}
            onKeyPress={(event) => (event.key === 'Enter' ? headerHitEnter() : undefined)}
            inputRef={lastNameRef}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </TableHeaderWrapper>
      );
    }, [filterModelQuery?.last_name, headerHitEnter, t]);

    const emailHeader = useCallback(() => {
      const shrink = filterModelQuery?.email != null || undefined;
      return (
        <TableHeaderWrapper>
          <AdminTableHeaderField
            autoComplete="off"
            label="E-mail"
            type="search"
            InputLabelProps={{ shrink }}
            onKeyPress={(event) => (event.key === 'Enter' ? headerHitEnter() : undefined)}
            inputRef={emailRef}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </TableHeaderWrapper>
      );
    }, [filterModelQuery?.email, headerHitEnter]);

    const dealerHeader = useCallback(() => {
      const shrink = filterModelQuery?.dealer != null || undefined;
      return (
        <TableHeaderWrapper>
          <AdminTableHeaderField
            autoComplete="off"
            label="Dealerholding"
            type="search"
            InputLabelProps={{ shrink }}
            onKeyPress={(event) => (event.key === 'Enter' ? headerHitEnter() : undefined)}
            inputRef={dealerRef}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </TableHeaderWrapper>
      );
    }, [filterModelQuery?.dealer, headerHitEnter]);

    const columns: Array<GridColDef> = useMemo(
      () =>
        getColumns({
          isSuperAdmin,
          showActivity: showActivityChart,
          smallPaddingClass: styles.smallPadding,
          firstNameHeader,
          lastNameHeader,
          emailHeader,
          dealerHeader,
          userFeatures,
          showServiceAccounts,
          t,
        }),
      [
        dealerHeader,
        emailHeader,
        firstNameHeader,
        isSuperAdmin,
        lastNameHeader,
        showActivityChart,
        styles.smallPadding,
        userFeatures,
        showServiceAccounts,
        t,
      ],
    );

    const onClickDownload = useCallback(() => {
      userStatsAllDownload({
        activities: activityType || undefined,
        date_from: dateFrom,
        date_to: dateTo,
        filters: requestFilters,
        is_service_account: showServiceAccounts,
        show_super_admins: showSuperAdmins,
        permissions,
        // ids: requestIds,
      }).catch(snackbarErrorHandler);
    }, [
      dateFrom,
      dateTo,
      requestFilters,
      showServiceAccounts,
      showSuperAdmins,
      snackbarErrorHandler,
      activityType,
      permissions,
    ]);

    const onSelectionChange = useCallback(
      (param: any) => {
        setSelectedProxy(param);
      },
      [setSelectedProxy],
    );

    const onPageChange = useCallback(
      (param: any) => {
        setPageProxy(param);
        if (gridRef?.current) {
          gridRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
      },
      [setPageProxy],
    );
    const onPageSizeChange = useCallback((param: any) => setLimit(param), [setLimit]);

    const onPaginationModelChange = useCallback(
      (param: GridPaginationModel) => {
        onPageChange(param.page);
        onPageSizeChange(param.pageSize);
      },
      [onPageChange, onPageSizeChange],
    );

    const checkMissingLocations = useCallback(async () => {
      setIsLoadingMissingLocations(true);
      const userHasMissingLocationsMapping: Record<string, boolean> = {};
      try {
        const usersData = users?.data;
        const dealerLocations: Record<string, string[]> = {};

        const getDealerLocationsByName = async (name: string) => {
          if (!dealerLocations[name]) {
            const res = await getDealerLocations(name);
            dealerLocations[name] = res;
          }

          return dealerLocations[name];
        };

        if (usersData) {
          // eslint-disable-next-line no-restricted-syntax
          for (const userData of usersData) {
            // eslint-disable-next-line camelcase
            const { allowed_dealers } = userData;
            if (allowed_dealers) {
              // eslint-disable-next-line no-await-in-loop,no-restricted-syntax
              for (const allowedDealer of allowed_dealers) {
                // eslint-disable-next-line no-await-in-loop
                const locations = await getDealerLocationsByName(allowedDealer);
                const invalidLocations = getUserInvalidLocations(locations, userData, allowedDealer);

                if (invalidLocations.size > 0) {
                  userHasMissingLocationsMapping[userData.id] = true;
                  break;
                }
              }
            }
          }
        }
      } catch (error) {
        snackbarErrorHandler(error as Error);
      }
      setUserMissingLocationMap(userHasMissingLocationsMapping);
      setIsLoadingMissingLocations(false);
    }, [snackbarErrorHandler, users?.data]);

    const handleSortChange = React.useCallback((sort: GridSortModel) => {
      setSortModel(sort);
    }, []);

    const renderedGrid = useMemo(() => {
      return (
        <StyledDataGrid
          ref={gridRef}
          loading={isLoading || isLoadingMissingLocations}
          autoHeight
          onRowSelectionModelChange={onSelectionChange}
          className={styles.root}
          paginationMode="server"
          rows={rows}
          columnHeaderHeight={60}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableRowSelectionOnClick
          columns={columns}
          onPaginationModelChange={onPaginationModelChange}
          paginationModel={{ page: page, pageSize: limit }}
          rowCount={users?.total || 0}
          checkboxSelection
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortChange}
        />
      );
    }, [
      isLoading,
      isLoadingMissingLocations,
      onSelectionChange,
      styles.root,
      rows,
      columns,
      onPaginationModelChange,
      page,
      limit,
      users?.total,
      sortModel,
      handleSortChange,
    ]);

    const { deleteUserDialogComponent, onClickDelete } = useAdminDeleteUsers({
      selected,
      setSelected: setSelectedProxy,
      refreshUserList,
    });

    return (
      <AdminMainWrapper
        title={title || t('USERS')}
        titleRightComponent={showActivityChart ? <div>{selectPeriod}</div> : <></>}
      >
        {showChart && (
          <div>
            <AdminUserChartNivo
              apiData={chartData}
              isLoading={isLoadingChart}
              getUserActivityUrl={getUserActivityUrl}
            />
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
          <DeleteIcon onClick={onClickDelete} disabled={selected.length === 0} />
          {showActivityChart && <DownloadIcon onClick={onClickDownload} />}
          <AddIcon />
          {isSuperAdmin && (
            <MissingLocationsIcon onClick={checkMissingLocations} disabled={isLoadingMissingLocations} />
          )}
        </div>
        {renderedGrid}
        {deleteUserDialogComponent}
      </AdminMainWrapper>
    );
  },
);
