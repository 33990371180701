import { Grid2, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { CarWindowResult } from '@japieglobal/shared/src/api';
import { ResultCountry } from './result-other-country';
import { AppSearchParamKeys, ColoredOutlinedButton, useAppSearchParams } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';

interface ResultOtherCountriesProps {
  windowResults: { [country: string]: CarWindowResult | undefined };
  windowIsLoading: { [country: string]: boolean };
  setSelectedCountry: (country: string) => void;
  selectedCountry: string;
  clickDealers: { [country: string]: string | undefined };
}

export const ResultOtherCountries = ({
  windowIsLoading,
  windowResults,
  setSelectedCountry,
  selectedCountry,
  clickDealers,
}: ResultOtherCountriesProps) => {
  const countryKeys = Object.keys(windowResults);
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);
  const [, setSearchParams] = useAppSearchParams();

  const groupedCountries = countryKeys.reduce<string[][]>((groups, key, index) => {
    const groupIndex = Math.floor(index / 5);
    if (!groups[groupIndex]) {
      groups[groupIndex] = [];
    }
    groups[groupIndex].push(key);
    return groups;
  }, []);

  const currentGroupIndex = groupedCountries.findIndex((group) => group.includes(selectedCountry));
  const currentTabIndex = currentGroupIndex !== -1 ? groupedCountries[currentGroupIndex].indexOf(selectedCountry) : 0;

  const handleTabChange = (groupIdx: number, newIndex: number) => {
    const newCountry = groupedCountries[groupIdx][newIndex];
    setSelectedCountry(newCountry);
    setSearchParams({
      [AppSearchParamKeys.COUNTRY_TAB]: newCountry,
      [AppSearchParamKeys.CLICK_DEALER]: clickDealers[newCountry],
    });
  };

  return (
    <Grid2 container>
      <Grid2 width="100%">
        {(isExpanded ? groupedCountries : [groupedCountries[0]]).map((group, groupIndex) => (
          <Tabs
            key={groupIndex}
            variant="fullWidth"
            value={currentGroupIndex === groupIndex ? currentTabIndex : -1}
            onChange={(e, newVal) => handleTabChange(groupIndex, newVal)}
            sx={{ mt: 2 }}
          >
            {group.map((country) => (
              <Tab
                key={country}
                label={
                  <ResultCountry
                    error={windowResults[country]?.error}
                    isLoading={windowIsLoading[country]}
                    country={country}
                    car={windowResults[country]?.car}
                  />
                }
                sx={{
                  fontSize: 13,
                  textTransform: 'none',
                  minWidth: '20%',
                  maxWidth: '20%',
                  height: '100%',
                  border: '1px groove',
                }}
              />
            ))}
          </Tabs>
        ))}
      </Grid2>
      <Grid2 mt={2} width="100%" display="flex" justifyContent="flex-end">
        {groupedCountries.length > 1 && (
          <ColoredOutlinedButton onClick={() => setExpanded(!isExpanded)}>
            {t(isExpanded ? 'SHOW_LESS' : 'SHOW_MORE')}
          </ColoredOutlinedButton>
        )}
      </Grid2>
    </Grid2>
  );
};
