import React from 'react';
import { Badge, styled } from '@mui/material';
import { CarResult, Equipment } from '@japieglobal/shared/src/types/car-types';
import { BooleanAppSearchParam, StringAppSearchParam, useAppSearchParams } from '@japieglobal/shared/src/components';
import { clickStateParser } from '../../../../utils/tri-state-parser';
import { ClickState } from '@japieglobal/shared/src/types/click-state';
import { OptionIcon } from '@japieglobal/shared/src/components';

interface CarWindowOptionCellProps {
  car: CarResult;
  equipmentOption: keyof Equipment;
  searchParamKey:
    | BooleanAppSearchParam.CLICK_EQUIPMENT_IS_DEALER
    | BooleanAppSearchParam.CLICK_EQUIPMENT_OFFICIAL_MAKE
    | StringAppSearchParam.CLICK_EQUIPMENT_IN_STOCK;
  onHoverContent?: string;
  hideBadge?: boolean;
  badgeColor?: string;
}

export const CarWindowEquipment = ({
  car,
  searchParamKey,
  equipmentOption,
  onHoverContent,
  hideBadge = true,
  badgeColor,
}: CarWindowOptionCellProps) => {
  const [searchParams, setSearchParams] = useAppSearchParams();
  const enabled = car.equipment?.[equipmentOption];
  const value = searchParams[searchParamKey];

  const handleClick = () => {
    switch (searchParamKey) {
      case StringAppSearchParam.CLICK_EQUIPMENT_IN_STOCK:
        setSearchParams({ [StringAppSearchParam.CLICK_EQUIPMENT_IN_STOCK]: clickStateParser(value as ClickState) });
        break;
      case BooleanAppSearchParam.CLICK_EQUIPMENT_IS_DEALER:
        setSearchParams({ [BooleanAppSearchParam.CLICK_EQUIPMENT_IS_DEALER]: !value });
        break;
      case BooleanAppSearchParam.CLICK_EQUIPMENT_OFFICIAL_MAKE:
        setSearchParams({ [BooleanAppSearchParam.CLICK_EQUIPMENT_OFFICIAL_MAKE]: !value });
        break;
    }
  };

  const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
      backgroundColor: badgeColor,
      height: 5,
      minWidth: 5,
    },
  });

  return (
    <StyledBadge variant="dot" invisible={hideBadge}>
      <OptionIcon
        onClick={handleClick}
        onHoverText={onHoverContent}
        toggleValue={value as ClickState | boolean}
        isEnabled={enabled}
        option={equipmentOption}
      />
    </StyledBadge>
  );
};
